.Nav-Logo img {
  height: 45px;
}

.Nav {
  display: flex;
  justify-content: space-between;
  height: 15.14vh;
  align-items: center;
  top: 0;
  z-index: 1;
  width: 100%;
  background: transparent;
}

.Nav-Logo {
  padding-left: 20px;
}

.Nav-Links {
  display: flex;
  padding-top: 13px;
  align-items: center;
  padding-left: 15px;
}

.Nav-Links button {
  font-size: 2.3vh;
  color: white;
  padding: 8px 32px;
}

.Nav-Links button:hover {
  border: 1px solid #FFFFFF;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.34);
  padding: 8px 32px;
}

.Nav-Link {
  display: none;
  margin-right: 7px;
}
.Nav-Link.active {
  color: white;
  border: 1px solid #FFFFFF;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.34);
  padding: 8px 32px;
  font-size: 2.3vh;
}
.Nav-Link.active:hover {
  cursor: pointer;
}
.Nav.color .Nav-Link.active {
  color: #132625;
  border: none;
  background: #B9057C;
  color: white;
}

.Nav-Download {
  display: none;
  border-radius: 50px;
  padding: 8px 32px;
  background: #B9057C;
  border: none;
  color: white !important;
  margin-right: 50px;
}

.Nav-Link-Small {
  margin-right: 5px;
}
.Nav-Link-Small button {
  color: #FFFFFF !important;
  font-size: 20px;
  font-weight: 600;
}
.Nav-Link-Small.active {
  font-size: 20px;
  color: white;
  border: 1px solid #FFFFFF;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.34);
  padding: 8px 32px;
  outline: none;
}

.Nav-Link-Small-Download {
  margin-top: -25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Nav-Link-Small-Download a {
  color: white;
  font-size: 18px;
  font-weight: 600;
  width: fit-content;
}
.Nav-Link-Small-Download .button-icon {
  width: 11%;
  margin-right: 2vw;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 24px;
  right: 25px;
  top: -5px;
}
.bm-burger-button span:nth-child(2) {
  top: 30% !important;
}
.bm-burger-button span:nth-child(3) {
  top: 60% !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
  width: 30px;
  border-radius: 5px;
  opacity: 1 !important;
  height: 17% !important;
}

/* Color/shape of burger icon bars on hover
.bm-burger-bars-hover {
  background: white;
} */

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px !important;
  width: 36px !important;
  right: 28px !important;
  top: 20px !important;
}

/* Color/shape of close button cross */
/* .bm-cross {
} */

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  width: 100% !important;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(183, 0, 121, 0.9);
  padding: 2.5em 1.5em 0;
  font-size: 25px;
  overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
}

/* Individual item */
.bm-item {
  margin-bottom: 30px;
  color: white;
}

/* Styling of overlay */
.bm-overlay {
  display: none;
}


.GoUpArrow {
  position: fixed;
  width: 30px;
  height: 30px;
  background-color: #B9057C;
  bottom: 18px;
  right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  transform: rotate(180deg);
}
.GoUpArrow.hidden{
  display: none;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Nav-Links {
    padding-top: 25px;
  }

  .Nav-Download {
    margin-right: 80px;
  }

  .bm-burger-button {
    right: 54px;
  }
  .bm-cross-button {
    top: 52px !important;
    right: 52px !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .Nav-Links {
    padding-left: 0;
    padding-right: 62px;
  }

  .Nav-Logo {
    position: relative;
    padding-left: 42px;
  }
  .Nav-Logo img {
    height: 7.4vh;
  }

  .Nav-Burger-Menu {
    display: none;
  }

  .Nav-Link-Small {
    display: none;
  }

  .Nav-Link {
    display: block;
  }

  .Nav-Download {
    display: inline-block;
    margin-right: 0;
  }
  .Nav-Download.white {
    border: 1px solid white;
    background: none;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}