html, button, body {
  font-family: 'Nunito-Light';
}
button:hover {
  cursor: pointer;
}
a {
  text-decoration: none;
}

.App {
  text-align: left;
  background-color: white;
  color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: left;
  background: url('./assets/background.png');
}
button:focus, button:active {
  outline: none !important;
}
button {
  border: none;
  background: transparent;
  outline: none !important;
}

.App-link {
  border: 1px solid #B9057C;
  box-sizing: border-box;
  border-radius: 14.6468px;
  width: 124.6px;
  height: 30px;
  font-family: Nunito;
  font-size: 12px;
  line-height: 15px;
  color: #B9057C;
}

.App-link-form {
  width: 124.6px;
  height: 30px;
  background: #B9057C;
  border-radius: 20px;
  color:white;
  font-family: Nunito;
  font-size: 12px;
  line-height: 15px;
}

.App-body {
  font-size: calc(8px + 1.5vmin);
}

.Footer {
  display: flex;
  justify-content: center;
  padding-right: 3%;
  background: #EDEDED;
  padding-bottom: 7.16vh;
  padding-top: 7.16vh;
}

.Copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5vh;
}

.Privacy-Policy-Link {
  display: flex;
  justify-content: flex-end;
}

.Privacy-Policy-Link a:link {
  color: #B9057C;
}

@media only screen and (max-width: 768px) {
  .App-logo {
    height: 110px;
  }
}

@media only screen and (max-width: 425px) {
  button {
    background-color: transparent;
  }
  .App-header {
    margin-top: 4%;
    left: 42%;
  }
  .App-logo {
    height: 95px;
  }
  .Footer {
    display: block;
  }
  .Copyright {
    font-size: 0.8rem;
  }
  .Privacy-Policy-Link {
    font-size: 0.8rem;
    justify-content: center;
    padding-top: 2%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}