.Header {
  width: 100%;
  background: linear-gradient(180deg, #B70079 0%, #852B8D 51.04%, #624A9B 74.48%, #267DB3 100%);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-top: -15.1vh;
  padding-top: 12vh;
}
.Header.HomePage {
  height: 92vh;
  background: linear-gradient(180deg, #B70079 0%, #267DB3 100%);
  overflow: hidden;
  flex-direction: column-reverse;
}

.Left {
  width: 100%;
  height: 28vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.Left .Phone {
  height: 50vh;
}
.Header.HomePage .Left {
  height: 60vh;
  margin-bottom: 15px;
  overflow: unset;
}
.Header.HomePage .Left .Phone {
  height: 60vh;
}


.Right {
  padding-top: 15px;
  padding-bottom: 0;
  width: 75%;
  animation: right 1.7s;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@keyframes right {
  0% { right: -500px; }
  100% { right: 0; }
}


.Header-Title {
  font-family: 'Unicorn-Calligraphy';
  font-size: 20px;
  color: #F0F0F0;
}
.Header-Title img {
  padding-top: 10px;
  transform: scale(1);
  width: 23.1vw;
}
.Header.HomePage .Header-Title img {
  width: 195px;
  padding-top: 0px;
}

.Header-Sub-Title {
  font-size: 15px;
  color: white;
  max-width: 55vw;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.Header-Download {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 16px;
  width: fit-content;
  margin-top: 10px;
}
.Header.HomePage .Header-Download {
  font-size: 17px;
}

.Header-DownloadButtons {
  display: none;
  margin-top: 15px;
  flex-direction: column-reverse;
  padding-left: 15%;

}
.Header-DownloadButton {
  display: flex;
  width: 190px;
  height: 50px;
  color: white;
  font-size: 16px;
  border: 2px solid white;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-bottom: 10px;
}
.Header.HomePage .Header-DownloadButton {
  font-size: 15px;
  font-weight: 600;
}

.button-icon {
  margin-right: 1vw;
  height: 63%;
  width: 15%;
  display: flex;
}

.Decor-Line-1 {
  position: absolute;
  left: 3vw;
  display: none;
  width: 21vw;
  height: 23.2vw;
}

.Decor-Line-2 {
  position: absolute;
  left: 32vw;
  top: 24.1vh;
  width: 18.55vw;
  height: 13.78vw;
  display: none;
}

.Header-DownloadText {
  display: none;
}

.Header-ScrollIcon {
  display: none;
}

.Header-ArrowDown {
  display: flex;
  padding-top: 5px;
  animation: arrow 1s infinite;
  position: relative;
}
@keyframes arrow {
  0% { top: 0; }
  100% { top: 5px; }
}

.Header-Phone-Shadow {
  width: 42vh;
  margin-top: 0;
  bottom: 10px;
}

.Header-PlayTrailer {
  display: flex;
  justify-content: center;
}

.Trailer-Modal {
  background-color: rgba(0, 0, 0, 0.54);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal-Overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.Modal-Close {
  position: absolute;
  right: 13.5vw;
  top: 10vh;
  width: 2.1vw;
  height: 2.1vw;
}
.Modal-Close button {
  width: 2.1vw;
  height: 2.1vw;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Header {
    justify-content: flex-start;
  }

  .Header.HomePage {
    flex-direction: column;
  }

  .Header.HomePage .Header-DownloadButton {
    font-size: 1.19vw;
  }

  .Header-DownloadButton {
    margin-bottom: 0;
    width: 13.2vw;
    height: 3.48vw;
    border-radius: 2.1vw;
  }

  .Header-Sub-Title {
    font-size: 1.5vw;
  }

  .Header-DownloadText {
    font-size: 1.5vw;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .Header {
    flex-direction: row;
    max-height: 62.6vh;
    align-items: flex-end;
    overflow: hidden;
    height: unset;
    margin-top: -15.1vh;
    padding-top: 0;
  }
  .Header.HomePage {
    height: 100vh;
    max-height: 100%;
    align-items: center;
    padding: 0 3vw;
    flex-direction: row;
  }
  
  .Left {
    width: 38%;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 0;
  }
  .Left .Phone {
    padding-right: 2.5vw;
    top: 37vh;
    height: 84vh;
  }
  .Header.HomePage .Left .Phone {
    top: 0;
  }

  .Right {
    padding: 0 0 40px 3vw;
    width: 52%;
  }
  .Header.HomePage .Right {
    margin-top: 0px;
    padding-bottom: 0px;
    justify-content: center;
    align-items: center;
  }

  .Header-Title {
    font-size: 7vh;
  }
  .Header-Title img {
    transform: scale(1);
    width: 23.1vw;
  }
  .Header.HomePage .Header-Title img {
    width: 45%;
  }

  .Header-Sub-Title {
    font-size: 2.4vh;
    max-width: 500px;
    margin-bottom: 2.8vw;
  }

  .Header-DownloadText {
    font-size: 2.4vh;
  }
  
  .Header-DownloadButtons {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    padding-left: 0;
  }

  .Header.HomePage .Header-DownloadButtons {
    margin-top: 10px;
  }

  .Decor-Line-1 {
    display: flex;
  }
  
  .Decor-Line-2 {
    display: flex;
  }

  .Header-DownloadText {
    display: block;
    text-align: center;
  }
  
  .Header-ScrollIcon {
    position: absolute;
    left: 50%;
    bottom: 4vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Header-Phone-Shadow {
    bottom: 0;
    margin-top: -1vh;
  }

  .Header-Download {
    margin-top: 0;
    flex-direction: row;
  }
  .Header.HomePage .Header-Download {
    flex-direction: column;
  }

  .Header-PlayTrailer {
    margin-bottom: 2vh;
  }
  .Header.HomePage .Header-PlayTrailer {
    margin-bottom: 8vh;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
