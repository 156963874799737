.Form-main {
    width: 382.76px;
    height: 590.11px;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    box-sizing: border-box;
    z-index: 20;
    position: relative;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.Form-modal {
    position: fixed;
    display:flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}
.Form-closeButton {
    position: absolute;
    top: 2%;
    right: 0%;
}
.Form-header {
    align-items: center;
    /* background: antiquewhite; */
    display: flex;
    margin-top: 8vh;
    flex-direction: column;
}
.Form-header-text {

    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 112%;
    /* identical to box height, or 24px */
    
    display: flex;
    align-items: center;
    text-align: center;
    color: #B9057C;
    
    
}
.Form-text {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 112%;
}
.Form-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.FormikForm-container {
    margin-top: 3vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.Form-text-container {
    align-items: center;
    display: flex;
    width: 269.94px;
    margin-top: 2vh;
    flex-direction: column;
    text-align: center;
    color: #3D3D3D;
}
.FormElement_Error {
    border-color: #DC9196;
}

.FormElement_Success {
    border-color: #86DE86;
}
.FormElement_Container {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

.FormElement {
    border: 1px solid #C63495;
    box-sizing: border-box;
    border-radius: 20px;
    width: 278px;
    height: 40px;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    color: #3D3D3D;
    padding-left: 10px;
}
.FormElement-textarea {
    border: 1px solid #C63495;
    box-sizing: border-box;
    border-radius: 20px;
    width: 278px;
    height: 100px;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #3D3D3D;
    padding-left: 10px;
    padding-top: 5px;
}

.FormElement_ErrorMessage {
    padding-left: 2%;
    color: #DC9196;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
}

.Form-saveButton {
    width: 124.6px;
    height: 30px;
    background: #B9057C;
    border-radius: 20px;
    color:white;
    font-family: Nunito;
    font-size: 12px;
    line-height: 15px;
    margin-top: 20px;
}

.TextField .TextBox {
      border-radius: 20px;
      height: 100px;
}

@media only screen and (max-width: 425px) {
    .Teaser {
        margin-bottom: 17%;
    }
    .Teaser-Text {
        font-size: 1.4rem;
        padding-right: 0;
    }
    video {
        width: 100%;
    }
}