body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Nunito-Regular';
  src: local('Nunito-Regular'), url(./fonts/Nunito-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito-Bold';
  src: local('Nunito-Bold'), url(./fonts/Nunito-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito-Light';
  src: local('Nunito-Light'), url(./fonts/Nunito-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Unicorn-Calligraphy';
  src: local('Unicorn-Calligraphy'), url(./fonts/Unicorn-Calligraphy.ttf) format('truetype');
}
