.FAQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 105px;
}

.QnA {
  width: 90%;
}

.Question {
  background: #FFFFFF;
  border: 2px solid #C63495;
  box-sizing: border-box;
  border-radius: 50px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 17px 8px 44px;
  color: #3D3D3D;
  font-size: 18px;
  animation: question 1s;
  position: relative;
}
@keyframes question {
  0% { left: -500px; opacity: 0; }
  100% { left: 0; opacity:1; }
}
.Question:hover {
  cursor: pointer;
}
.Question.open {
  margin-bottom: 0;
}

.Q-Arrow {
  transition: transform 1s;
}
.Q-Arrow.upArrow {
  transform: rotate(180deg);
}

.Answer {
  margin: 0 22px;
  padding: 30px 23px 40px 27px;
  background: #F9F9F9;
  font-size: 18px;
  animation: answer 1s;
}
@keyframes answer {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .FAQ {
    margin-bottom: 136px;
  }

  .QnA {
    width: 62.5%;
  }

  .Question {
    height: 50px;
    padding: 0 17px 0 44px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
