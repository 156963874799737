.AboutUs {
  padding-top: 50px;
}

.Section1 {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 40px;
  animation: section 1.5s;
}
@keyframes section {
  0% { opacity: 0; }
  100% { opacity:1; }
}
.Section1-Image {
  width: 256px;
  margin-bottom: 40px;
}

.Text {
  display: flex;
  flex-direction: column;
  color: black;
  align-items: center;
  max-width: 85%;
}
.Text ul {
  margin-top: 10px;
}

.Text-Title-Wearlog {
  width: 275px;
}

.Text-Sub-Title {
  font-size: 30px;
  font-family: 'Nunito-Regular';
}

.Text-Content {
  padding-top: 10px;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Nunito-Regular';
}

.Section2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 97px;
  animation: section 1s;
  align-items: center;
}
.Section2 .Image {
  width: 100%;
  margin-top: 30px;
}

.AboutUs-LineDecor {
  display: none;
}

video { 
  width: 100%;
  margin-bottom: 40px;
}

video:focus {
  outline: none;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .Section1 {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 0;
  }

  .Section1-Image {
    width: 25.78vw;
    height: 26.81vw;
    margin-bottom: 0px;
  }

  .Text {
    max-width: 43%;
    align-items: flex-start;
  }

  .Section2 {
    flex-direction: row;
    justify-content: space-around;
  }

  .Section2 .Image {
    display: flex;
    height: 60.25vh;
    width: unset;
    margin-top: 0;
  }

  .AboutUs-LineDecor {
    display: flex;
    justify-content: center;
    position: relative;
    width: 18.55vw;
    left: 41vw;
  }

  .Text-Title-Wearlog {
    width: 19.1vw;
  }
  
  .Text-Sub-Title {
    font-size: 2.1vw;
  }

  .Text-Content {
    font-size: 18px;
    line-height: 23px;
    font-family: 'Nunito-Regular';
  }

  video { 
    width: 50%;
    margin-bottom: 0;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}